import { HttpErrorResponse } from "@angular/common/http";
import { createAction, props } from "@ngrx/store";

import { QuizListItemSt } from "@shared/models";

export const load = createAction('[Learning Quizzes] Load');

export const loadSuccess = createAction('[Learning Quizzes] Load success', props<{ quizzes: QuizListItemSt[] }>());

export const loadFail = createAction('[Learning Quizzes] Load fail', props<{ error: HttpErrorResponse }>());

export const setInitialState = createAction('[Learning Quizzes] Set initial state');
